import { Box, Text, Flex, Container } from 'theme-ui';
import ImgLoader from '~/components/Generic/ImgLoader';

const FitnessSection = ({ section, reference, forwardSx = {} }) => {
  return (
    <Flex
      sx={{
        px: ['3rem', null, '8rem'],
        ...forwardSx,
      }}
    >
      <Container
        sx={{
          maxWidth: 'var(--max-width)',
          mx: 'auto',
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: 'center',
          columnGap: '41px',
        }}
      >
        {section.media.map((card) => {
          return (
            <Box ref={reference} key={card.id} sx={{ width: ['100%', '50%'], mb: ['5.2rem', '8.1rem'] }}>
              <Box sx={{ position: 'relative' }}>
                <ImgLoader
                  image={card.image}
                  forwardSx={{
                    width: '100%',
                    height: ['23.9rem', '34.9rem'],
                    borderTopLeftRadius: '20px',
                    borderBottomRightRadius: '40px',
                    mb: ['21px', '43px'],
                  }}
                />
              </Box>
              <Text as="h3" variant="h4" sx={{ fontSize: ['2.2rem', '2.9rem'] }}>
                {card.title}
              </Text>
              <Box
                sx={{
                  minWidth: '102px',
                  p: {
                    fontSize: ['1.5rem', '1.4rem'],
                    lineHeight: ['1.6rem', '1.9rem'],
                    strong: {
                      height: 'fit-content',
                      fontSize: '1.6rem',
                    },
                  },
                  'p:first-of-type': { height: '80px' },
                  'p:last-of-type': { mb: ['14px', '27px'] },
                }}
                dangerouslySetInnerHTML={{ __html: card.body?.childMarkdownRemark.html }}
              />
              <Flex
                sx={{
                  '@supports (gap: 0)': {
                    gap: ['3rem', '5rem'],
                  },
                }}
              >
                {card.dataPoints.map((point) => (
                  <Box key={JSON.stringify(point.label)} sx={{ width: 'fit-content' }}>
                    <Flex
                      sx={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '100%',
                        height: ['74px', '90px'],
                        width: ['74px', '90px'],
                        backgroundColor: 'black',
                        color: 'white',
                        mb: ['9px', '12px'],
                        '@supports not (gap: 0)': {
                          mr: ['5rem', '7.5rem'],
                        },
                      }}
                    >
                      <Text
                        sx={{
                          position: 'relative',
                          textAlign: 'center',
                          fontSize: ['2.1rem', '2.6rem'],
                        }}
                      >
                        +{point.percent}%
                      </Text>
                    </Flex>
                    <Text sx={{ fontSize: ['12px', '16px'], textAlign: 'center', fontWeight: [400, 500] }}>
                      {point.label}
                      <sup>{point.labelSuperscript}</sup>
                    </Text>
                  </Box>
                ))}
              </Flex>
            </Box>
          );
        })}
      </Container>
    </Flex>
  );
};
export default FitnessSection;
