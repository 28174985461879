import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import Science from '~/components/Science';
import ContentFeatureHero from '~/components/Content/Page/ContentFeatureHero';

const SciencePage = ({ data }) => {
  const { page } = data;
  const { hero, mobileHero } = page;

  return (
    <Layout transparentNav={page.transparentNav} navColor={page.navColor}>
      <Metadata />
      <ContentFeatureHero hero={hero} mobileHero={mobileHero} />
      <Science page={page} />
    </Layout>
  );
};

export default SciencePage;

export const query = graphql`
  query SciencePage($locale: String) {
    page: contentfulPage(slug: { eq: "science" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`;
