import { Box, Container, Flex, Text } from 'theme-ui';
import React from 'react';

const Paragraph = React.memo(function Paragraph({ section, forwardSx = {}, width }) {
  return (
    <Flex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'top',
        px: ['3rem', null, '8rem'],
        py: ['4rem', '6rem'],
        flexDirection: 'column',
        backgroundColor: 'inherit',
        ...forwardSx,
      }}
    >
      <Container
        sx={{
          maxWidth: 'var(--max-width)',
          mx: 'auto',
        }}
      >
        <Text
          as="h2"
          variant="h4"
          sx={{ width: '100%', fontSize: ['28px', '32px'], lineHeight: '24px', pb: section.description ? '2rem' : 0 }}
        >
          {section.title}
        </Text>
        <Flex
          sx={{
            '--paragraph-size': '1.8',
            '--line-height': ['2rem', '2.5rem'],
            flexWrap: 'wrap',
            gap: '41px',
          }}
        >
          {section.description && (
            <Box
              sx={{
                flexShrink: 1,
                maxWidth: !section.extraColumnBody && `${width}px`,
                width: '100%',
                minWidth: `${width}px`,
                textRendering: 'geometricPrecision',
                ol: {
                  mt: 0,
                  pl: ['1rem', '1.4rem'],
                  fontFamily: 'body',
                  textAlign: 'left',
                  fontSize: 'var(--paragraph-size) !important',
                  lineHeight: 'var(--line-height)',
                  whiteSpace: 'wrap',
                },
                li: {
                  marginLeft: ['1rem', '.5rem'],
                  mb: '1rem',
                },
                p: {
                  margin: 0,
                  minWidth: '1px',
                  textRendering: 'geometricPrecision',
                  fontSize: 'var(--paragraph-size) !important',
                  lineHeight: 'var(--line-height)',
                },
              }}
              dangerouslySetInnerHTML={{ __html: section.description?.childMarkdownRemark.html }}
            />
          )}
          {section.extraColumnBody && (
            <Box
              sx={{
                width: '100%',
                minWidth: `${width}px`,
                textRendering: 'geometricPrecision',
                ol: {
                  mt: 0,
                  pl: ['1rem', '1.4rem'],
                  fontFamily: 'body',
                  textAlign: 'left',
                  fontSize: 'var(--paragraph-size) !important',
                  lineHeight: 'var(--line-height)',
                  whiteSpace: 'wrap',
                },
                li: {
                  marginLeft: ['1rem', '.5rem'],
                },
                p: {
                  m: 0,
                  p: 0,
                  minWidth: '1px',
                  textRendering: 'geometricPrecision',
                  fontSize: 'var(--paragraph-size) !important',
                  lineHeight: 'var(--line-height)',
                },
              }}
              dangerouslySetInnerHTML={{ __html: section.extraColumnBody?.childMarkdownRemark.html }}
            />
          )}
        </Flex>
      </Container>
    </Flex>
  );
});

export default Paragraph;
