import { Container } from 'theme-ui';
import Paragraph from '~/components/Science/paragraph';
import FitnessSection from '~/components/Science/fitness';
import useWindowSize from '~/hooks/components/use-window-size';
import React, { useEffect, useState, useRef } from 'react';
import DeferredParagraph from '~/components/Science/deferredParagraph';

const Science = React.memo(function Science({ page }) {
  const [cardWidth, setCardWidth] = useState();
  const { width } = useWindowSize();
  const sections = page.sections;
  const cardRef = useRef(null);

  useEffect(() => {
    // get width for card to set in other components
    if (cardRef.current) {
      setCardWidth(cardRef.current.getBoundingClientRect().width);
    }
    return () => {
      cardRef.current = null;
    };
  }, [cardRef.current, width]);

  return (
    <Container
      variant="fullwidth"
      sx={{
        'strong, a, h3': {
          fontWeight: 'medium',
        },
      }}
    >
      {sections.map((section) => {
        if (section.slug === 'science-how-does-it-work') {
          return (
            <Paragraph
              key={section.id}
              width={cardWidth}
              section={section}
              forwardSx={{
                background: '#F5F5F5',
                h2: { maxWidth: ['9em', `${cardWidth}px`] },
              }}
            />
          );
        }
        if (section.slug === 'science-what-can-it-do-for-me') {
          return (
            <Paragraph
              width={cardWidth}
              key={section.id}
              section={section}
              forwardSx={{
                py: 0,
                pt: ['3rem', '7rem'],
                pb: ['3rem', '4rem'],
                h2: { maxWidth: ['9em', `${cardWidth}px`], whiteSpace: 'wrap !important' },
                background: '#FFFFFF',
              }}
            />
          );
        }
        if (section.slug === 'science-sport-fitness') {
          return (
            <FitnessSection
              key={section.id}
              forwardSx={{ background: '#FFFFFF' }}
              section={section}
              reference={cardRef}
            />
          );
        }
        if (section.slug === 'science-where-do-i-start') {
          return (
            <DeferredParagraph
              width={cardWidth}
              key={section.id}
              section={section}
              forwardSx={{
                py: '4rem',
                background: '#E1E1E1',
                h2: { maxWidth: ['9em', `${cardWidth}px`], mr: '2rem' },
              }}
            />
          );
        }

        if (section.slug === 'science-learn-more') {
          return (
            <DeferredParagraph
              width={cardWidth}
              key={section.id}
              section={section}
              forwardSx={{
                background: '#FFFFFF',
                p: { width: [null, '30em'] },
                h2: { maxWidth: ['9em', `${cardWidth}px`] },
                flexDirection: 'column',
                margin: 0,
                pb: ['5.4rem', '6.9rem'],
                'p:first-of-type': { margin: 0, mb: ['14px', '25px'] },
              }}
            />
          );
        }
        if (section.slug === 'science-references') {
          return (
            <DeferredParagraph
              key={section.id}
              section={section}
              list={true}
              forwardSx={{
                background: '#F5F5F5',
                flexDirection: 'column',
                h2: { maxWidth: ['9em', `${cardWidth}px`] },
                ol: {
                  pl: ['.5rem', '1.1rem'],
                  mb: 0,
                  li: {
                    fontSize: ['10px', '12px'],
                    lineHeight: ['14px', '21px'],
                  },
                },
              }}
            />
          );
        }
        return null;
      })}
    </Container>
  );
});

export default Science;
